import React, {useMemo} from "react"
import { graphql } from "gatsby"


import styles from '@styles/pages/news.module.scss'
import Layout from "@comp/layout"
import SEO from '@comp/seo'

import TransitionAnimationLink from '@comp/transition_animation_link'
import ContactBlock from '@comp/contact_block'

import PageTitle from '@comp/page_title'
// markup
const NewsListPage = ({ 
  location,
  data 
}) => {


  const recentNewsPots = useMemo( () => {
    return data.allMarkdownRemark.edges?.map( (post) => {
      return {
        ...post.node.frontmatter,
        datetime: post.node.frontmatter.date.replace(/\//g,'-')
      }
    });
  },[data.allMarkdownRemark.edges])

  const recentNewsListElm = useMemo( () => {
    return recentNewsPots.map( (post, index) => {
      return (
        <article key={`news_${index}`} className={styles.news__post}>
          <TransitionAnimationLink to={ post.slug } className={styles.news__post__link}>
            <div className={styles.news__post__img}>
              <img src={ post.thumbnailImg } alt=""/>
            </div>
            <h1 className={styles.news__post__title}>{ post.title }</h1>
            <time className={styles.news__post__date}>{ post.date }</time>
          </TransitionAnimationLink>
        </article>
      )
    })
  },[recentNewsPots])

  return (
    <Layout location={location} pageName="newsList">
      <SEO
        title={`過去の取り組み`}
        description={`MIRAI SAKE COMPANYに関する過去の取り組みをご紹介しています。`}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.inner}>
          <PageTitle
            label='過去の取り組み'
            labelEn='NEWS'
          />
          <div className={styles.news__list}>
            { recentNewsListElm }
          </div>

          <section className={styles.contact}>
            <ContactBlock
              copy={'次の取組みをご一緒しませんか？'}
              txt={'私たちの活動に関心を持っていただけましたら、下記フォームよりお問い合わせください。'}
            />
          </section>
        </div>
      </main>
    </Layout>
  )
}

export default NewsListPage


export const pageQuery = graphql`
  {
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {category: {eq: "news"}}}) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            date(formatString: "YYYY/MM/DD")
            thumbnailImg
          }
        }
      }
    }
  }
`